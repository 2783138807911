<template>
  <div id="product">
    <el-dialog
      :title="airFormTitle"
      width="1200px"
      :visible.sync="airDialogVisible"
      :close-on-click-modal="false"
      @close="airDialogClose"
      ><el-form
        ref="airFormRef"
        :model="airForm"
        :rules="productFormRules2"
        label-position="right"
        label-width="150px"
      >
        <el-form-item label="员工" prop="employeeId">
          <el-select
            v-model="airForm.employeeId"
            placeholder="请选择员工"
            @change="EmployeeChange"
            filterable
            remote
            clearable
            :remote-method="getEmployee"
            :disabled="handleType !== 'add' && handleType !== 'update'"
          >
            <el-option
              v-for="item in EmployeeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="岗位" prop="post">
          <el-input
            v-model="airForm.post"
            placeholder="请输入岗位"
            clearable
            readonly
          />
        </el-form-item>
        <el-form-item label="部门" prop="dept">
          <el-input
            v-model="airForm.dept"
            placeholder="请输入部门"
            clearable
            readonly
          />
        </el-form-item>
        <el-form-item label="入职日期" prop="entryDate">
          <el-date-picker
            v-model="airForm.entryDate"
            type="datetime"
            placeholder="选择入职日期"
            value-format="yyyy-MM-dd hh:mm:ss"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="计划转正日期" prop="plannedConversionDate">
          <el-date-picker
            v-model="airForm.plannedConversionDate"
            type="datetime"
            placeholder="选择计划转正日期"
            value-format="yyyy-MM-dd hh:mm:ss"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="实际转正日期" prop="actualConversionDate">
          <el-date-picker
            v-model="airForm.actualConversionDate"
            type="datetime"
            placeholder="选择实际转正日期"
            value-format="yyyy-MM-dd hh:mm:ss"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="个人申请" prop="individualApplication">
          <el-input
            v-model="airForm.individualApplication"
            placeholder="请输入个人申请"
            type="textarea"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>
        <el-form-item label="人事行政部审批" v-if="handleType === 'update'">
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            v-if="!airForm.personnelSignature"
            @click.stop="handleAdd2('personnel')"
          >
            审批
          </el-button>
          <p v-else>已审批</p>
        </el-form-item>
        <el-col
          :span="24"
          v-if="handleType !== 'add' && airForm.personnelSignature"
        >
          <el-form-item label="转正意见" prop="positiveOpinion">
            <el-input
              v-model="airForm.positiveOpinion"
              placeholder="请输入转正意见"
              type="textarea"
              readonly
            />
          </el-form-item>
          <el-form-item label="转正工资标准/福利" prop="fee">
            <el-input
              v-model="airForm.fee"
              placeholder="请输入转正工资标准/福利"
              type="textarea"
              readonly
            />
          </el-form-item>
          <el-form-item label="人事行政部意见" prop="personnelOpinion">
            <el-input
              v-model="airForm.personnelOpinion"
              placeholder="请输入人事行政部意见"
              type="textarea"
              readonly
            />
          </el-form-item>
          <el-form-item label="人事行政部签字" prop="personnelSignature">
            <el-input
              v-model="airForm.personnelSignature"
              placeholder="请输入人事行政部签字"
              type="textarea"
              readonly
            />
          </el-form-item>
        </el-col>
        <el-form-item label="部门领导审批" v-if="handleType === 'update'">
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            v-if="!airForm.deptHeadSignature"
            @click.stop="handleAdd2('dept')"
          >
            审批
          </el-button>
          <p v-else>已审批</p>
        </el-form-item>
        <el-col
          :span="24"
          v-if="handleType !== 'add' && airForm.deptHeadSignature"
        >
          <el-form-item label="部门意见" prop="deptOpinion">
            <el-input
              v-model="airForm.deptOpinion"
              placeholder="请输入部门意见"
              type="textarea"
              readonly
            />
          </el-form-item>
          <el-form-item label="部门领导意见" prop="deptHeadOpinion">
            <el-input
              v-model="airForm.deptHeadOpinion"
              placeholder="请输入部门领导意见"
              type="textarea"
              readonly
            />
          </el-form-item>
          <el-form-item label="部门领导签字" prop="deptHeadSignature">
            <el-input
              v-model="airForm.deptHeadSignature"
              placeholder="请输入部门领导签字"
              type="textarea"
              readonly
            />
          </el-form-item>
        </el-col>
        <el-form-item label="总经理审批" v-if="handleType === 'update'">
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            v-if="!airForm.generalManagerSignature"
            @click.stop="handleAdd2('general')"
          >
            审批
          </el-button>
          <p v-else>已审批</p>
        </el-form-item>
        <el-col
          :span="24"
          v-if="handleType !== 'add' && airForm.generalManagerSignature"
        >
          <el-form-item label="总经理审批" prop="generalManagerApproval">
            <el-input
              v-model="airForm.generalManagerApproval"
              placeholder="请输入总经理审批"
              type="textarea"
              readonly
            />
          </el-form-item>
          <el-form-item label="总经理签字" prop="generalManagerSignature">
            <el-input
              v-model="airForm.generalManagerSignature"
              placeholder="请输入总经理签字"
              type="textarea"
              readonly
            />
          </el-form-item>
        </el-col>
      </el-form>
      <div slot="footer">
        <el-button @click="airDialogVisible = false"> 取 消 </el-button>
        <el-button type="primary" @click="productFormSubmit"> 确 定 </el-button>
      </div>
      <el-dialog
        width="60%"
        title="新增领导审批"
        :visible.sync="innerVisible"
        :close-on-click-modal="false"
        append-to-body
      >
        <el-form
          ref="productFormRef"
          :model="airDetailForm"
          :rules="productFormRules"
          label-position="right"
          label-width="150px"
        >
          <el-form-item
            label="转正意见"
            prop="positiveOpinion"
            v-if="leaderType === 'personnel'"
          >
            <el-input
              v-model="airDetailForm.positiveOpinion"
              placeholder="请输入转正意见"
              type="textarea"
              clearable
              :readonly="airDetailForm.inputType === 'detail'"
            />
          </el-form-item>
          <el-form-item
            label="转正工资标准/福利"
            prop="fee"
            v-if="leaderType === 'personnel'"
          >
            <el-input
              v-model="airDetailForm.fee"
              placeholder="请输入转正工资标准/福利"
              type="textarea"
              clearable
              :readonly="airDetailForm.inputType === 'detail'"
            />
          </el-form-item>
          <el-form-item
            label="人事行政部意见"
            prop="personnelOpinion"
            v-if="leaderType === 'personnel'"
          >
            <el-input
              v-model="airDetailForm.personnelOpinion"
              placeholder="请输入人事行政部意见"
              type="textarea"
              clearable
              :readonly="airDetailForm.inputType === 'detail'"
            />
          </el-form-item>
          <el-form-item
            label="人事行政部签字"
            prop="personnelSignature"
            v-if="leaderType === 'personnel'"
          >
            <el-input
              v-model="airDetailForm.personnelSignature"
              placeholder="请输入人事行政部签字"
              type="textarea"
              clearable
              :readonly="airDetailForm.inputType === 'detail'"
            />
          </el-form-item>
          <el-form-item
            label="部门意见"
            prop="deptOpinion"
            v-if="leaderType === 'dept'"
          >
            <el-input
              v-model="airDetailForm.deptOpinion"
              placeholder="请输入部门意见"
              type="textarea"
              clearable
              :readonly="airDetailForm.inputType === 'detail'"
            />
          </el-form-item>
          <el-form-item
            label="部门领导意见"
            prop="deptHeadOpinion"
            v-if="leaderType === 'dept'"
          >
            <el-input
              v-model="airDetailForm.deptHeadOpinion"
              placeholder="请输入部门领导意见"
              type="textarea"
              clearable
              :readonly="airDetailForm.inputType === 'detail'"
            />
          </el-form-item>
          <el-form-item
            label="部门领导签字"
            prop="deptHeadSignature"
            v-if="leaderType === 'dept'"
          >
            <el-input
              v-model="airDetailForm.deptHeadSignature"
              placeholder="请输入部门领导签字"
              type="textarea"
              clearable
              :readonly="airDetailForm.inputType === 'detail'"
            />
          </el-form-item>
          <el-form-item
            label="总经理审批"
            prop="generalManagerApproval"
            v-if="leaderType === 'general'"
          >
            <el-input
              v-model="airDetailForm.generalManagerApproval"
              placeholder="请输入总经理审批"
              type="textarea"
              clearable
              :readonly="airDetailForm.inputType === 'detail'"
            />
          </el-form-item>
          <el-form-item
            label="总经理签字"
            prop="generalManagerSignature"
            v-if="leaderType === 'general'"
          >
            <el-input
              v-model="airDetailForm.generalManagerSignature"
              placeholder="请输入总经理签字"
              type="textarea"
              clearable
              :readonly="airDetailForm.inputType === 'detail'"
            />
          </el-form-item>
        </el-form>
        <div slot="footer">
          <el-button @click="innerVisible = false"> 取 消 </el-button>
          <el-button type="primary" @click="productFormSubmit2">
            确 定
          </el-button>
        </div>
      </el-dialog>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="姓名">
        <el-input
          v-model="searchForm.name"
          placeholder="请输入姓名"
          clearable
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="waterList.list"
      row-key="id"
      :cell-style="{ padding: '6px' }"
      :header-cell-style="{ background: '#f8f8f9' }"
      :height="getTableHeight()"
    >
      <el-table-column prop="name" label="员工姓名" />
      <el-table-column prop="entryDate" label="入职时间" />
      <el-table-column prop="plannedConversionDate" label="计划转正日期" />
      <el-table-column prop="actualConversionDate" label="实际转正日期" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="waterList.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  getConversionList,
  deleteConversionList,
  addConversionList,
  getConversionDetail,
  updateConversionDetail,
  getEmployeeList,
  deptApproveConversion,
  generalApproveConversion,
  personnelApproveConversion,
} from "@/api/humanManagement/humanManagement.js";

export default {
  data() {
    return {
      airDialogVisible: false,
      innerVisible: false,
      airFormTitle: "",
      airForm: {},
      productPage: {
        list: [],
        total: 0,
      },
      waterList: {
        list: [],
        total: 0,
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
      },
      typeList: [],
      typeMap: [],
      tableData: [],
      handleType: "",
      handleDetailType: "",
      runningTime: [],
      airDetailForm: {},
      productFormRules: {
        disinfectionObject: [
          {
            required: true,
            message: "时间名称不能为空",
            trigger: ["change"],
          },
        ],
      },
      productFormRules2: {
        employeeId: [
          {
            required: true,
            message: "员工不能为空",
            trigger: ["change", "blur"],
          },
        ],
        post: [
          {
            required: true,
            message: "岗位不能为空",
            trigger: ["change", "blur"],
          },
        ],
        dept: [
          {
            required: true,
            message: "部门不能为空",
            trigger: ["change", "blur"],
          },
        ],
        entryDate: [
          {
            required: true,
            message: "入职日期不能为空",
            trigger: ["change", "blur"],
          },
        ],
        plannedConversionDate: [
          {
            required: true,
            message: "计划转正日期不能为空",
            trigger: ["change", "blur"],
          },
        ],
        actualConversionDate: [
          {
            required: true,
            message: "实际转正日期不能为空",
            trigger: ["change", "blur"],
          },
        ],
        individualApplication: [
          {
            required: true,
            message: "个人申请不能为空",
            trigger: ["change", "blur"],
          },
        ],
      },
      EmployeeList: [],
      leaderType: "",
    };
  },
  async created() {
    let params = {
      pageNum: 1,
      pageSize: 10,
    };
    let waterList = await getConversionList(params);
    this.waterList = waterList;
  },
  methods: {
    async getEmployee(name){
      if(name){
        let params2 = {
          pageNum: 1,
          pageSize: 100,
          name
        };
        let EmployeeList = await getEmployeeList(params2);
        console.log(EmployeeList,'EmployeeList')
        this.EmployeeList = EmployeeList.list;
      }else{
        this.EmployeeList = [];
      }
    
    },
    airDialogClose() {
      this.$refs.airFormRef.resetFields();
    },
    productFormSubmit() {
      this.$refs.airFormRef.validate(async (valid) => {
        if (valid) {
          if (this.handleType === "add") {
            console.log(this.airForm, "this.airForm");
            await addConversionList(this.airForm);
          } else if (this.handleType === "update") {
            let params = this.airForm;
            await updateConversionDetail(params);
          }
          this.waterList = await getConversionList(this.searchForm);
          this.airDialogVisible = false;
        }
      });
    },
    async productFormSubmit2() {
      this.$refs.productFormRef.validate(async (valid) => {
        this.airDetailForm.id = this.airForm.id;
        if (valid) {
          console.log("进来了");
          console.log(this.leaderType);
          if (this.leaderType == "personnel") {
            await personnelApproveConversion(this.airDetailForm);
          }
          if (this.leaderType == "dept") {
            await deptApproveConversion(this.airDetailForm);
          }
          if (this.leaderType == "general") {
            await generalApproveConversion(this.airDetailForm);
          }
          await getConversionDetail(this.airDetailForm.id).then((res) => {
            this.airForm = { ...res };
          });
          this.innerVisible = false;
        }
      });
    },
    handleAdd() {
      this.airFormTitle = "新增员工转正申请";
      this.handleType = "add";
      this.airDialogVisible = true;
    },
    handleAdd2(leaderType) {
      this.innerVisible = true;
      this.leaderType = leaderType;
    },
    handleDelete(index, row) {
      this.$confirm("确认删除？", "提示", {
        type: "warning",
      }).then(async () => {
        await deleteConversionList(row.id);
        if (this.productPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--;
        }
        this.waterList = await getConversionList(this.searchForm);
      });
    },
    handleUpdate(index, row) {
      this.airFormTitle = "员工转正申请";
      this.handleType = "update";
      getConversionDetail(row.id).then((res) => {
        this.airForm = { ...res };
        // this.runningTime = [res.startTime || "", res.endTime || ""];
        this.airDialogVisible = true;
      });
    },
    handleInfo(index, row) {
      this.airFormTitle = "员工转正申请";
      this.handleType = "detail";
      getConversionDetail(row.id).then((res) => {
        this.airForm = { ...res };
        console.log({ ...res });
        // this.runningTime = [res.startupTime || "", res.offTime || ""];
        this.airDialogVisible = true;
      });
    },
    handleSearch() {
      this.searchForm.pageNum = 1;
      getConversionList(this.searchForm).then((res) => {
        console.log(res, "res");
        this.waterList = res;
      });
    },
    pageNumChange(pageNum) {
      this.searchForm.pageNum = pageNum;
      getConversionList(this.searchForm).then((res) => {
        this.waterList = res;
      });
    },
    pageSizeChange(pageSize) {
      this.searchForm.pageSize = pageSize;
      this.searchForm.pageNum = 1;
      getConversionList(this.searchForm).then((res) => {
        this.waterList = res;
      });
    },
    EmployeeChange(value) {
      if (value) {
        let Employee = this.EmployeeList.find((item) => item.id === value);
        console.log(Employee, "Employee");
        this.airForm.name = Employee.name;
        this.airForm.post = Employee.post;
        this.airForm.dept = Employee.dept;
      } else {
        this.airForm.name = "";
        this.airForm.post = "";
        this.airForm.dept = "";
      }
    },
  },

  watch: {
    runningTime(val, oldVal) {
      console.log(val, "val");
      this.airForm.entryExitDate = val.join("-");
    },
    airDialogVisible(val) {
      if (!val) {
        this.airForm = {};
        this.runningTime = [];
      }
    },
  },
};
</script>

<style>
#product section {
  margin: 0px 0px 20px 20px;
}
</style>
